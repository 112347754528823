import { BILLING_ENTITY_TYPE_INDIVIDUAL } from "@/constants/billingInformations";
import { PHONE_TYPE_MOBILE } from "@/constants/common";

export default {
  type: "users",
  firstname: null,
  lastname: null,
  email: null,
  phone: null,
  phone_extension: null,
  phone_type: PHONE_TYPE_MOBILE,
  other_phones: [],
  is_staff: false,
  locale: null,
  password: null,
  password_confirmation: null,
  profile_image: null,
  billing_entity_type: BILLING_ENTITY_TYPE_INDIVIDUAL,
  billing_company_name: "N/A",
  billing_firstname: "N/A",
  billing_lastname: "N/A",
  billing_country: "N/A",
  billing_state: "N/A",
  billing_city: "N/A",
  billing_zipcode: "N/A",
  billing_address: "N/A",
  billing_email: "NA@NA.NA",
  restrict_to_locations: false,
  restrict_to_service_centers: false,
  restrict_to_establishments: false,
  restrict_to_cafeterias: false,
  relationshipNames: [
    "organization",
    "reseller",
    "roles",
    "allowedLocations",
    "allowedServiceCenters",
    "allowedEstablishments",
    "allowedCafeterias",
  ],
  roles: [
    {
      type: "roles",
      id: null,
    },
  ],
  organization: {
    type: "organizations",
    id: null,
  },
  reseller: {
    type: "resellers",
    id: null,
  },
  allowedLocations: [],
  allowedServiceCenters: [],
  allowedEstablishments: [],
  allowedCafeterias: [],
};
