<template>
  <div class="form-group">
    <label class="form-control-label"> {{ label }} </label>
    <div v-if="image" class="profile-image card-img pb-4">
      <img :src="`${image}`" class="profile-image argon-image" />
    </div>
    <div v-else class="profile-image">
      <img src="/img/placeholder.jpg" class="argon-image" />
    </div>
    <div class="image-upload">
      <base-button
        v-if="image"
        type="button"
        class="btn btn-sm btn-danger"
        @click="removeImage"
      >
        <span>
          <i class="fa fa-times" />
          {{ $t("COMMON.REMOVE_IMAGE") }}
        </span>
      </base-button>
      <base-button type="button" class="btn btn-sm btn-primary">
        <label v-if="!image" :for="`imageInput-${componentId}`" class="mb-0">
          {{ $t("COMMON.SELECT_IMAGE") }}
        </label>
        <label v-else :for="`imageInput-${componentId}`" class="mb-0">
          {{ $t("COMMON.CHANGE_IMAGE") }}
        </label>
        <input
          :id="`imageInput-${componentId}`"
          :ref="`imageInput-${componentId}`"
          accept="image/*"
          type="file"
          style="display: none"
          @input="onFileChange"
        />
      </base-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "image-selector",

  props: {
    defaultImage: {
      type: String,
      default: null,
      description: "Default image to diplay, should be model value",
    },
    label: {
      type: String,
      default: null,
      description: "Label of the field",
    },
    ressource_name: {
      type: String,
      default: null,
      description:
        "Ressource name to be uploaded with, must be allowed in the api",
    },
    ressouce_id: {
      type: String,
      default: null,
      description:
        "Ressource id to be uploaded with, must be allowed in the api",
    },
    field: {
      type: String,
      default: null,
      description:
        "Ressource field to be uploaded with, must be allowed in the api",
    },
  },

  setup() {},

  data() {
    return {
      image: this.defaultImage,
      componentId: null,
    };
  },

  mounted() {
    this.componentId = this._uid;
  },

  methods: {
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.uploadImage(files[0]);
      this.createImage(files[0]);
    },

    createImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    async uploadImage(file) {
      if (file) {
        try {
          await this.$store.dispatch("files/upload", {
            image: file,
            ressource_name: this.ressource_name,
            ressource_id: this.ressource_id,
            field: this.field,
          });
          const image_url = await this.$store.getters["files/url"];
          this.$emit("imageChanged", image_url);
        } catch (error) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });
        }
      }
    },

    removeImage() {
      this.$refs[`imageInput-${this.componentId}`].value = null;
      this.image = null;
      this.$emit("imageChanged", null);
    },
  },

  watch: {
    defaultImage(defaultImage) {
      this.image = defaultImage;
    },
  },
};
</script>
