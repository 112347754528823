<template>
  <el-select
    v-model="serviceCentersModel"
    @change="serviceCentersChanged"
    autocomplete="new-password"
    :placeholder="$t('COMMON.SERVICECENTERS')"
    :filterable="true"
    :multiple="true"
    :disabled="disabled"
    remote
    :remote-method="getserviceCenters"
    :loading="loading"
  >
    <el-option
      v-for="serviceCenter in serviceCentersList"
      :key="serviceCenter.id"
      :value="serviceCenter.id"
      :label="`${serviceCenter.name}`"
    >
    </el-option>
  </el-select>
</template>

<script>
import { difference } from "lodash";
import { Option, Select } from "element-ui";

export default {
  name: "service-centers-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    serviceCenters: {
      type: Array,
      default: () => [],
      description: "serviceCenters",
    },
    organization: {
      type: String,
      default: null,
      description: "Organization id",
    },
  },

  data() {
    return {
      serviceCentersModel: [...this.serviceCenters].map((item) => item.id),
      serviceCentersList: {},
    };
  },

  setup() {},

  created() {
    this.getserviceCenters(null, this.serviceCenters);
  },

  methods: {
    async getserviceCenters(query = null, serviceCenters = null) {
      try {
        this.loading = true;
        let params = {
          sort: "name",
          filter: {
            ...(query ? { search: query } : {}),
            ...(serviceCenters
              ? { ids: serviceCenters.map((item) => item.id) }
              : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (serviceCenters) {
          params.page.size = 999999;
        }

        if (this.organization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.organization,
            },
          };
        }

        await this.$store.dispatch("serviceCenters/list", params);
        const serviceCentersArr = await this.$store.getters[
          "serviceCenters/list"
        ];
        this.serviceCentersList = {};
        serviceCentersArr.forEach((serviceCenter) => {
          this.serviceCentersList[serviceCenter.id] = serviceCenter;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async serviceCentersChanged(serviceCenters) {
      this.$emit(
        "serviceCentersChanged",
        [...serviceCenters].map((item) => ({
          type: "service-centers",
          id: item,
        }))
      );
    },
  },

  watch: {
    serviceCenters(serviceCenters) {
      if (serviceCenters && serviceCenters.length != 0) {
        const serviceCentersData = serviceCenters.map((item) => item.id);
        if (
          difference(serviceCentersData, this.serviceCentersModel).length !== 0
        ) {
          this.serviceCentersModel = serviceCentersData;
          this.getserviceCenters(null, serviceCenters);
        }
      } else {
        this.serviceCentersModel = [];
      }
    },
    organization(organization) {
      this.getserviceCenters();
    },
  },
};
</script>
