<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <base-checkbox
      v-model="user.is_staff"
      class="mb-3"
      v-if="showIsStaff && $currentUser().is_staff"
      @change="
        () => {
          onFormChanged();
          user.organization.id = null;
          user.reseller.id = null;
        }
      "
    >
      <span class="form-control-label">{{ $t("USERS.IS_STAFF") }}</span>
    </base-checkbox>
    <validation-error :errors="apiValidationErrors.is_staff" />

    <base-input
      v-if="
        !user.is_staff &&
        showReseller &&
        $currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)
      "
      :label="$t('COMMON.RESELLER')"
      :placeholder="$t('COMMON.RESELLER')"
    >
      <reseller-selector
        :disabled="!!user.id"
        :reseller="user.reseller ? user.reseller.id : null"
        :filterable="true"
        :showAll="false"
        :allowNone="true"
        @resellerChanged="
          (resellerId) => {
            user.reseller = { type: 'resellers', id: resellerId };
            user.organization = { type: 'organizations', id: null };
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.reseller" />

    <base-input
      v-if="
        !user.is_staff &&
        showOrganization &&
        $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
      "
      :label="$t('COMMON.ORGANIZATION')"
      :placeholder="$t('COMMON.ORGANIZATION')"
    >
      <organization-selector
        :disabled="!!user.id"
        :filterReseller="user.reseller ? user.reseller.id : null"
        :organization="user.organization ? user.organization.id : null"
        :filterable="true"
        :showAll="false"
        :allowNone="true"
        @organizationChanged="
          (organizationId) => {
            user.organization = { type: 'organizations', id: organizationId };
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.organization" />

    <base-checkbox
      v-model="user.restrict_to_locations"
      class="mb-3"
      v-if="user.organization.id"
      @change="
        () => {
          onFormChanged();
        }
      "
    >
      <span class="form-control-label">
        {{ $t("USERS.RESTRICT_TO_LOCATIONS") }}
      </span>
    </base-checkbox>
    <validation-error :errors="apiValidationErrors.restrict_to_locations" />

    <base-input
      :label="`${$t('COMMON.LOCATION')}`"
      :placeholder="$t('COMMON.LOCATION')"
      v-if="
        !!user.organization?.id &&
        user.restrict_to_locations &&
        $currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)
      "
    >
      <locations-selector
        :locations="user.allowedLocations"
        :filterable="true"
        :showAll="false"
        :multiple="true"
        :organization="user.organization.id"
        @locationsChanged="
          (locations) => {
            user.allowedLocations = locations;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.location" />

    <base-checkbox
      v-model="user.restrict_to_service_centers"
      class="mb-3"
      v-if="user.organization.id"
      @change="
        () => {
          onFormChanged();
        }
      "
    >
      <span class="form-control-label">
        {{ $t("USERS.RESTRICT_TO_SERVICE_CENTERS") }}
      </span>
    </base-checkbox>

    <validation-error
      :errors="apiValidationErrors.restrict_to_service_centers"
    />
    <base-input
      :label="`${$t('COMMON.SERVICECENTERS')}`"
      :placeholder="$t('COMMON.SERVICECENTERS')"
      v-if="
        user.restrict_to_service_centers &&
        $currentUserCan($permissions.PERM_VIEW_ANY_SERVICECENTERS)
      "
    >
      <service-centers-selector
        :serviceCenters="user.allowedServiceCenters"
        :organization="user.organization.id"
        @serviceCentersChanged="
          (serviceCenters) => {
            user.allowedServiceCenters = serviceCenters;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.allowedServiceCenters" />

    <base-checkbox
      v-model="user.restrict_to_establishments"
      class="mb-3"
      v-if="user.organization.id"
      @change="
        () => {
          onFormChanged();
        }
      "
    >
      <span class="form-control-label">
        {{ $t("USERS.RESTRICT_TO_ESTABLISHMENTS") }}
      </span>
    </base-checkbox>
    <validation-error
      :errors="apiValidationErrors.restrict_to_establishments"
    />

    <base-input
      :label="`${$t('COMMON.ESTABLISHMENT')}`"
      :placeholder="$t('COMMON.ESTABLISHMENT')"
      v-if="
        user.restrict_to_establishments &&
        $currentUserCan($permissions.PERM_VIEW_ANY_ESTABLISHMENTS)
      "
    >
      <establishments-selector
        :establishments="user.allowedEstablishments"
        :organization="user.organization.id"
        @establishmentsChanged="
          (establishments) => {
            user.allowedEstablishments = establishments;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.establishment" />

    <base-checkbox
      v-model="user.restrict_to_cafeterias"
      class="mb-3"
      v-if="user.organization.id"
      @change="
        () => {
          onFormChanged();
        }
      "
    >
      <span class="form-control-label">
        {{ $t("USERS.RESTRICT_TO_CAFETERIAS") }}
      </span>
    </base-checkbox>
    <validation-error :errors="apiValidationErrors.restrict_to_cafeterias" />

    <base-input
      :label="`${$t('COMMON.CAFETERIA')}`"
      :placeholder="$t('COMMON.CAFETERIA')"
      v-if="
        user.restrict_to_cafeterias &&
        $currentUserCan($permissions.PERM_VIEW_ANY_CAFETERIAS)
      "
    >
      <cafeterias-selector
        :cafeterias="user.allowedCafeterias"
        :organization="user.organization.id"
        @cafeteriasChanged="
          (cafeterias) => {
            user.allowedCafeterias = cafeterias;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.cafeteria" />

    <image-selector
      :defaultImage="user.profile_image"
      ressource_name="users"
      :ressource_id="user.id ? user.id : 0"
      field="profile_image"
      @imageChanged="
        (file_url) => {
          user.profile_image = file_url;
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.attachment" />

    <base-input
      :label="`${$t('COMMON.FIRSTNAME')} (*)`"
      :placeholder="$t('COMMON.FIRSTNAME')"
      v-model="user.firstname"
      @change="
        () => {
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.firstname" />

    <base-input
      :label="`${$t('COMMON.LASTNAME')} (*)`"
      :placeholder="$t('COMMON.LASTNAME')"
      v-model="user.lastname"
      @change="
        () => {
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.lastname" />

    <base-input
      :label="`${$t('COMMON.EMAIL')} (*)`"
      :placeholder="$t('COMMON.EMAIL')"
      v-model="user.email"
      @change="
        () => {
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.email" />

    <!-- Phone number -->
    <div class="row">
      <div class="col-2">
        <base-input :label="`${$t('COMMON.TYPE')}`">
          <el-select
            :label="$t('COMMON.TYPE')"
            :placeholder="$t('COMMON.TYPE')"
            v-model="user.phone_type"
            @change="
              (type) => {
                user.phone_type = type;
                onFormChanged();
              }
            "
          >
            <el-option
              v-for="(value, key) in phoneTypesOptions"
              :key="key"
              :value="value"
              :label="$t(`COMMON.PHONE_TYPE_${value}`)"
            />
          </el-select>
        </base-input>
      </div>
      <div class="col-7">
        <base-input :label="`${$t('COMMON.PHONE')} (*)`">
          <phone-number-input
            :phoneNumber="user.phone"
            @phoneNumberChanged="
              (phone) => {
                user.phone = phone;
                onFormChanged();
              }
            "
            :required="true"
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.phone" />
      </div>
      <div class="col-3">
        <base-input
          :label="`${$t('COMMON.EXTENSION')}`"
          v-model="user.phone_extension"
          @change="
            () => {
              onFormChanged();
            }
          "
          :inputClasses="'extension-input'"
        />
        <validation-error :errors="apiValidationErrors.phone_extension" />
      </div>
    </div>

    <!-- Other phone numbers -->
    <base-input :label="`${$t('COMMON.OTHER_PHONES')}`">
      <phone-numbers-selector
        :phoneNumbers="user.other_phones"
        @phoneNumbersChanged="
          (phoneNumbers) => {
            user.other_phones = phoneNumbers;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.other_phones" />

    <base-input
      :label="`${$t('COMMON.ROLE')} (*)`"
      :placeholder="$t('COMMON.ROLE')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ROLES)"
    >
      <role-selector
        :role="user.roles.length ? user.roles[0].id : null"
        :filterable="true"
        :showAll="false"
        :filterReseller="user.reseller ? user.reseller.id : null"
        :filterOrganization="user.organization ? user.organization.id : null"
        @roleChanged="
          (roleId) => {
            if (user.roles[0]) {
              user.roles[0].id = roleId;
            } else {
              user.roles[0] = { type: 'roles', id: roleId };
            }
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.roles" />

    <base-input
      :label="`${$t('COMMON.PROFESSION')}`"
      :placeholder="$t('COMMON.PROFESSION')"
      v-model="user.profession"
      @change="
        () => {
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.profession" />

    <base-input
      :label="`${$t('COMMON.LOCALE')} (*)`"
      :placeholder="$t('COMMON.LOCALE')"
    >
      <locale-selector
        :locale="user.locale"
        @localeChanged="
          (locale) => {
            user.locale = locale;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.locale" />

    <base-input
      v-model="user.password"
      type="password"
      name="new_password"
      prepend-icon="fa fa-key"
      :placeholder="`${$t('COMMON.PASSWORD')} ${user.id ? '' : '(*)'}`"
      :label="`${$t('COMMON.PASSWORD')} ${user.id ? '' : '(*)'}`"
      @change="
        () => {
          onFormChanged();
        }
      "
      @focus="showPasswordHint = true"
      @blur="showPasswordHint = false"
    />
    <div class="mb-4" v-if="showPasswordHint">
      <small class="text-muted font-weight-bolder">{{
        $t("USERS.PASSWORD_COMPLEXITY")
      }}</small>
    </div>
    <validation-error :errors="apiValidationErrors.password" />

    <base-input
      v-model="user.password_confirmation"
      type="password"
      name="confirm_password"
      autocomplete="on"
      prepend-icon="fa fa-key"
      :placeholder="`${$t('COMMON.CONFIRM_PASSWORD')} ${user.id ? '' : '(*)'}`"
      :label="`${$t('COMMON.CONFIRM_PASSWORD')} ${user.id ? '' : '(*)'}`"
      @change="
        () => {
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.password_confirmation" />

    <h2 style="display: none">{{ $t("COMMON.BILLING_ADDRESS") }}</h2>

    <billing-informations-form
      style="display: none"
      :billingInformationsData="user"
      :formErrors="formErrors"
      @onChangeBillingInformations="billingInformationsChanged"
    />

    <div class="my-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{ user.id ? $t("USERS.EDIT_USER") : $t("USERS.ADD_USER") }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import { phoneTypesOptions } from "@/constants/common";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import ImageSelector from "@/components/ImageSelector.vue";
import RoleSelector from "@/components/RoleSelector.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import ResellerSelector from "@/components/ResellerSelector.vue";
import BaseCheckbox from "@/components/Inputs/BaseCheckbox.vue";
import LocaleSelector from "@/components/LocaleSelector.vue";
import BillingInformationsForm from "@/components/BillingInformationsForm.vue";
import PhoneNumberInput from "@/components/PhoneNumberInput.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import ServiceCentersSelector from "@/components/ServiceCentersSelector.vue";
import EstablishmentsSelector from "@/components/EstablishmentsSelector.vue";
import CafeteriasSelector from "@/components/CafeteriasSelector.vue";
import PhoneNumbersSelector from "@/components/PhoneNumbersSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    ImageSelector,
    RoleSelector,
    OrganizationSelector,
    ResellerSelector,
    BaseCheckbox,
    LocaleSelector,
    BillingInformationsForm,
    PhoneNumberInput,
    LocationsSelector,
    ServiceCentersSelector,
    EstablishmentsSelector,
    CafeteriasSelector,
    PhoneNumbersSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: {
    userData: {
      type: Object,
    },
    formErrors: {
      type: Array,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showReseller: {
      type: Boolean,
      default: true,
    },
    showOrganization: {
      type: Boolean,
      default: true,
    },
    showIsStaff: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    let userData = { ...this.userData };
    userData = this.$fillUserOrganizationData(userData);
    return {
      user: userData,
      phoneTypesOptions,
      showPasswordHint: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      let userData = cloneDeep(this.user);
      userData = this.$fillUserOrganizationData(userData);
      this.$emit("userSubmitted", userData);
    },

    billingInformationsChanged(billingInformations) {
      this.user = { ...this.user, ...billingInformations };
      this.onFormChanged();
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    userData(userData) {
      if (userData) {
        this.user = cloneDeep(userData);
        if (!this.user.organization) {
          this.user.organization = {
            type: "organizations",
            id: null,
          };
        }
      }
    },
  },
};
</script>
